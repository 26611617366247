import React, { useState, useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { PoolDetails } from '@opulous/web/src/shared/types'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import * as Sentry from '@sentry/react'
import WalletContext from '../../../context/context'
import { Alert } from '@mui/material'
import { Skeleton } from '@mui/material'
import {
  DialogTitleText,
  StyledContent,
  StyledDialogTitle,
  StyledDialogAction,
  StyledDialog,
} from '../InvestModal'
import Typography from '@mui/material/Typography'
import StyledLoadingButton from '@opulous/web/src/components/Common/StyledLoadingButton'
import { formatOpulAmount } from '../../../utils'
import { makePoolWithdrawTransaction } from '@opulous/web/src/helpers/AlgorandHelper'
import BigNumber from 'bignumber.js'
import { sendSignedTransactions, signTransactions } from 'src/services/my-algo'
import { algorandErrorString } from 'src/services/my-algo/errors'

const Widget = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1, 1.5),
  border: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',
  backgroundColor: theme.palette.common.white,
}))

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(2),
  margin: theme.spacing(0, 0.25),
}))

const Flex = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}))

const Amount = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
}))

const StyledAlert = styled(Alert)(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 600,
  flexShrink: 1,
  fontSize: '16px',
  lineHeight: 1,
  '& .MuiAlert-icon': {
    fontSize: '16px',
  },
  padding: '8px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '16px 0px 0px 0px !important',
  width: '100% !important',
}))

interface WithdrawModalProps {
  open: boolean
  onClose: (value: boolean) => void
  pool: PoolDetails
  stakeIndex: number
  onWithdraw?: (txIds: string[]) => void
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  open,
  onClose,
  onWithdraw,
  pool,
  stakeIndex,
}) => {
  const {
    state: { wallet, walletType, connector },
  } = useContext(WalletContext)
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleWithdrawStake = async () => {
    try {
      setError('')
      setIsLoading(true)

      Sentry.captureMessage(JSON.stringify({
        from: wallet,
        appId: pool.pool.appId,
        stakeIndex,
        tokenId: pool.pool.tokenId,
        poolType: pool.pool.type,
      }))

      const withdrawTxn = await makePoolWithdrawTransaction({
        from: wallet,
        appId: pool.pool.appId,
        stakeIndex,
        tokenId: pool.pool.tokenId,
        poolType: pool.pool.type,
      });

      Sentry.captureMessage(JSON.stringify({
        from: wallet,
        txn: withdrawTxn
      }))
      const signedTxns = await signTransactions([withdrawTxn], connector, walletType);
      await sendSignedTransactions(signedTxns);
      onWithdraw?.([withdrawTxn.txID()]);
      onClose(true)
    } catch (ex) {
      Sentry.captureException(ex)
      setError(algorandErrorString(ex) || 'An error ocurred while withdrawing, please try again');
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setError('')
    onClose(false)
  }

  const historyRec = pool.history?.find((x) => x.index === stakeIndex && x.type === 'staked')
  BigNumber.config({ EXPONENTIAL_AT: 1e+9 });
  const activeStakesCount =
    historyRec?.currentBalance &&
      historyRec?.projectedStake !== undefined
      ? formatOpulAmount(
        new BigNumber(historyRec?.projectedStake).toString(),
        2
      )
      : 0

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledDialogTitle>
        <DialogTitleText variant="h6" textAlign="left">
          Withdraw from pool
        </DialogTitleText>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </StyledDialogTitle>
      <StyledContent>
        <Widget>
          {!isLoading && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Label color="textSecondary">Amount to withdraw</Label>
              </Box>
              <Flex mt={1}>
                <Amount data-testid="withdraw-amount">{activeStakesCount}</Amount>
                <Typography variant="h6" color="textSecondary">
                  OPUL
                </Typography>
              </Flex>
            </>
          )}
          {isLoading && (
            <>
              <Skeleton variant="text"></Skeleton>
              <Skeleton variant="text"></Skeleton>
              <Skeleton variant="text"></Skeleton>
            </>
          )}
        </Widget>
        {!!error && (
          <StyledAlert variant="filled" severity="error">
            {error}
          </StyledAlert>
        )}
      </StyledContent>
      <StyledDialogAction>
        <StyledLoadingButton
          variant="contained"
          size="small"
          borderRadius="32px"
          onClick={handleWithdrawStake}
          loading={isLoading}
        >
          Confirm withdrawal
        </StyledLoadingButton>
      </StyledDialogAction>
    </StyledDialog>
  )
}

export default WithdrawModal
